.image-container {
  width: 60%;
  margin-bottom: 2em;
}

.blog-image {
  width: 100%;
  display: block;
  margin-bottom: 0.3em;  /* Small gap between image and caption */
}

.image-caption {
  font-size: 0.9em;
  color: #666;
  margin: 0;  /* Remove default paragraph margins */
  width: 100%;
} 